@import "./variables";
@import "./pageLayout";
@import "./typography";
@import "./tableMain.scss";
@import "./customerOverview.scss";
@import "./components/index.scss";
@import "./workflow.scss";
@import "./communicationTemplates.scss";
@import "./createworkflow.scss";
@import "./invoiceOverview.scss";
@import "./customerPoratl.scss";
@import "./dashboard.scss";
@import "./customerWorkflow.scss";
@import "./adhocUpload.scss";
@import "./userProfile.scss";
@import "./onBording.scss";
@import "./landingPage.scss";
@import "./settings.scss";
@import "./pricingPayment.scss";
@import "./IconStyle.scss";
@import "./fountIconStyles.scss";
@import "./favIconStyleChanges.scss";
@import "./report.scss";
@import "./Information.scss";
@import url("./whatsappPreview.scss");
@import url("./customer-preview.scss");
@import url("./stairStepTable.scss");
@import url("./PaymentPlan.scss");
@import url("./components/queryBuilder.scss");
@import "./components/toastify.scss";
@import "./notification.scss";
@import "./contactStatusMaintenance.scss";
@import url("./components/toastify.scss");
@import "./locationTrackerInput.scss";
@import url("./legal_action-debts.scss");
@import url("./legal_action-defendants.scss");
@import url("./legal_action-counsel.scss");
@import url("./legal_action-documents.scss");
@import "./consumerReviewGrouping.scss";
//V2 Style
@import "./customer-overview.scss";
@import "./customizeLabel.scss";
@import "./statusMaintance.scss";
@import "./performanceDashboard.scss";
@import "./bankruptcy.scss";
@import "./manualGrouping.scss";
@import "./holidayblackout.scss";
@import "./itemization.scss";
@import "./sidebar.scss";
@import "./worklist.scss";


a {
  color: inherit;
  text-decoration: inherit;
}

.mr {
  margin: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.pd {
  padding: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-12 {
  padding-top: 12px;
}

.icon-button.primary {
  &svg path {
    fill: var(--text-color);
  }

  &:hover svg path {
    fill: var(--white);
  }
}

.icon-button.tertiary {
  &svg path {
    fill: var(--text-color);
  }

  &:hover svg path {
    fill: var(--primary);
  }
}

.text-primary {
  color: var(--primary) !important;
}

.text-error {
  // color: var(--primary) !important;
  color: #fd372a !important;
}

.action-container {
  background-color: var(--white);
  box-shadow: var(--elevation-2);
  width: 200px;
  border-radius: 5px;
  border: 0.5px solid var(--border);
  padding: 20px 20px;
  text-align: center;
}

.action-container-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & button {
    margin: 8px;
  }
}

.pb-10 {
  padding-bottom: 10px;
}

.paymentcomment {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 68%;
  white-space: "nowrap";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.app_access {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.errors:hover {
  background: #ffdfdc;
  color: #fd372a;
  border-radius: 5px;
}

.paymentplancard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border-radius: 5px;
  padding: 10px;
  height: 85px;
  overflow: hidden;
  border: 1px solid #cdced9;
  background: #fff;
  margin: 5px;
}

.bot_card_details {
  width: 5px;
  height: 5px;
  background-color: #adadad;
  border-radius: 5px;
}

.add_card {
  background-color: #f0f5ff;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.headline-dot-icon path {
  color: #adadad;
  fill: #adadad;
}

.mx-icon {
  display: flex;

  &--path-fill-primary>svg>path {
    fill: $primary;
  }

  &--path-fill-success>svg>path {
    fill: $success;
  }

  &--path-fill-error>svg>path {
    fill: $error;
  }

  &--path-fill-info>svg>path {
    fill: $info;
  }

  &--path-fill-disabled>svg>path {
    fill: #787878;
  }
}

.mx-icon-button {
  border-radius: 5px;
  cursor: pointer;
  height: fit-content;
  width: fit-content;

  &:hover {
    background-color: $background;
  }
}

.text {
  &-primary {
    color: $primary;
  }

  &-secondary {
    color: $text-secondary;
  }

  &-success {
    color: $success;
  }

  &-error {
    color: $error;
  }

  &-info {
    color: $info;
  }

  &-trinery {
    color: $text-white;
  }
}

.mx-text-currency {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin-right: 2px;
}

.mx-chip {
  padding: 0px 5px;
  background-color: #f0f5ff;
  color: $primary;
  border-radius: 5px;

  &--error {
    background-color: lighten($error, 38%);
    color: $error;
  }

  &--success {
    background-color: #f3fff9;
    color: #0fb158;
  }

  &--info {
    background-color: lighten($info, 38%);
    color: $info;
  }

  &--primery {
    background-color: #e4f0fc;
    color: $primary;
  }
}

[data-baseweb="form-control-container"] {
  margin-bottom: 12px !important;
}

.row {
  display: flex;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.align-items-center {
  align-items: center;
}

.rte-floatpanel-paragraphop{
  display: none !important;
}

//Add Role Package